<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
      <template v-slot:btns>
        <div class="btns">
          <el-button type="primary" size="medium" @click="addHandle">新建文稿</el-button>
        </div>
      </template>
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="id"
          label="文档ID">
        </el-table-column>
        <el-table-column
          prop="title"
          label="文档标题">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="accessUrl"
          width="270"
          label="访问地址">
          <template slot-scope="scope">
            <a :href="scope.row.accessUrl+'?id='+scope.row.id" target="_blank">
              <el-button type="text" style="white-space:normal;">{{scope.row.accessUrl+'?id='+scope.row.id}}</el-button>
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="operateAccountName"
          label="创建人">
        </el-table-column>
        <el-table-column
          width="190"
          prop="val"
          label="操作">
          <template slot-scope="scope">
              <el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <bidtype-detail ref="bidtypeDetail" :info="info"></bidtype-detail>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {getDocumentList} from '../../api/system'
import BidtypeDetail from './coms/bidtype-detail.vue'
import DocAdd from './coms/doc-add.vue'
export default {
  data () {
    return {
      info:{},
      type:'add',
      caseTypeId:'',
      total:100,
      form:{
        perPage:10,
        page:1,
      },
      conditions:[
        {value:'title',label:'类型名称'}
      ],
      tableData:[
        
      ],
      multipleSelection:[],
      applyFilter:[
        {text: '诉讼业务', value: '1'}, 
        {text: '婚姻家庭', value: '2'}, 
        {text: '合同纠纷', value: '3'},
        {text: '非诉讼业务', value: '4'},
        {text: '法律顾问', value: '5'},
        {text: '劳动纠纷', value: '6'},
        {text: '金融证券', value: '7'},
        {text: '公司经营', value: '8'},
        {text: '法律文书', value: '9'},
      ],
        typeFilter:[
            {text: '诉讼业务', value: 1},
            {text: '非诉讼业务', value: 2}, 
        ],
        subjectFilter:[
            {text: '超级管理员', value: '1'},
            {text: '管理员', value: '2'}, 
            {text: '技术组', value: '3'}, 
            {text: '运营组', value: '4'}, 
            {text: '财务组', value: '5'}, 
            {text: '产品组', value: '6'}, 
        ],
        statusFilter:[
            {text: '有效', value: 1},
            {text: '无效', value: 0}
        ]

    };
  },
  components: {
    TableSearch,
    BidtypeDetail,
    DocAdd
  },
  computed: {},
  methods: {
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage
        },para);
      this.getList();
    },
    getList(){
      getDocumentList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage =val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){
      this.type = 'add';
      this.$router.push({path:'/systemManagement/documentEditor'});
    },
    editHandle(row){
      this.type="edit";
      this.$router.push({path:'/systemManagement/documentEditor',query:{id:row.id}});
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>